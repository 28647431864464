// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:09+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            c215ef70ec37bfb8d7ea8a278ec2448b
//    Signature:      eyJpdiI6Im1SZEc4OVhPZjhIRnJITVJnQ1RGREE9PSIsInZhbHVlIjoiSnRmdVwvOUJtQWFCUGlTTEVKS09CdlB0cVh3QVhFMlVKMVBScHZHMXREUmVtSVB0OFNyMmE0dU5WdUVCRFFQdUFMdzBvRW54U1VxZEJhVGp4dlVEdTZyNUNYbHo2YWVMbm53aFRDZlBVazh2aElXSmVaQ1l3alUrSkk3OWRkcmlwTm50M2pnOVwvdnVYV3htRDJEV2RhWHgxZE90c1orV2JyZGE0OHVhOVV5QWdxS2pmWDRHV3laZWEzdlJkZ3lVeW1HRjBPZ3RCUFhwQWNxc0ZYeFF2M0llaFRcL1JMbGVnVk9VZ1BiNGRjdWJaemNhanJJM1M1VlY3NkpGTndRNE9mbTczTThycUxUeG1IdkhyejJJa3UyXC84bHV1TUlRNU13N0FYWEk3cUxUckZwSEZBMmlvYmpaYWFUakN0STQyT3pDbUs5ZnFcLzVmc2FHQmVRRE9jbkVoZGpsUUhIV0pBZkMzVlNza1ZocG9LeWZQVytHYkdCYkc1Mkc2S3pWQ2RSS0tPNGxZXC84VVRQSlFkOGR1elwvMzBSQlE9PSIsIm1hYyI6IjBjODYwYWZmMjQ0Y2U4N2NkZDQyNzlkNWE3OTYzNTY4N2I1NWNhY2U1MTI1ZDE3MTBjOTI3ZTU4ODZlMDkzYWQifQ==
if (!customElements.get('share-button')) {
  customElements.define('share-button', class ShareButton extends DetailsDisclosure {
    constructor() {
      super();

      this.elements = {
        shareButton: this.querySelector('button'),
        shareSummary: this.querySelector('summary'),
        closeButton: this.querySelector('.share-button__close'),
        successMessage: this.querySelector('[id^="ShareMessage"]'),
        urlInput: this.querySelector('input')
      }
      this.urlToShare = this.elements.urlInput ? this.elements.urlInput.value : document.location.href;

      if (navigator.share) {
        this.mainDetailsToggle.setAttribute('hidden', '');
        this.elements.shareButton.classList.remove('hidden');
        this.elements.shareButton.addEventListener('click', () => { navigator.share({ url: this.urlToShare, title: document.title }) });
      } else {
        this.mainDetailsToggle.addEventListener('toggle', this.toggleDetails.bind(this));
        this.mainDetailsToggle.querySelector('.share-button__copy').addEventListener('click', this.copyToClipboard.bind(this));
        this.mainDetailsToggle.querySelector('.share-button__close').addEventListener('click', this.close.bind(this));
      }
    }

    toggleDetails() {
      if (!this.mainDetailsToggle.open) {
        this.elements.successMessage.classList.add('hidden');
        this.elements.successMessage.textContent = '';
        this.elements.closeButton.classList.add('hidden');
        this.elements.shareSummary.focus();
      }
    }

    copyToClipboard() {
      navigator.clipboard.writeText(this.elements.urlInput.value).then(() => {
        this.elements.successMessage.classList.remove('hidden');
        this.elements.successMessage.textContent = window.accessibilityStrings.shareSuccess;
        this.elements.closeButton.classList.remove('hidden');
        this.elements.closeButton.focus();
      });
    }

    updateUrl(url) {
      this.urlToShare = url;
      this.elements.urlInput.value = url;
    }
  });
}
